<template>
    <div class="create_order_page pj_padding"
         :class="isMobile ? 'create_order_mobile' : 'pj_padding'">
        <div class="order_wrap">
            <h1 v-if="!edit && showTitle">
                Оформление заказа
            </h1>
            <a-result
                v-if="!loading && orderEmpty && !createEmptyOrder"
                title="Для оформления заказа добавьте товар в корзину">
                <template #icon>
                    <a-icon
                        type="shopping"
                        theme="twoTone"/>
                </template>
                <template #extra>
                    <a-button
                        type="primary"
                        size="large"
                        @click="openCatalog()">
                        Перейти в каталог
                    </a-button>
                </template>
            </a-result>
            <div
                v-if="loading"
                class="flex justify-center">
                <a-spin/>
            </div>
            <template v-else>
                <template v-if="showOrderForm">
                    <a-spin
                        :spinning="reload"
                        size="large">
                        <div :class="isMobile || 'grid gap-5 wrap_grid'">
                            <div class="min-w-0">
                                <a-form-model
                                    ref="orderForm"
                                    :model="form"
                                    :rules="rules">
                                    <WidgetSwitch
                                        v-for="item in orderForm.orderForm"
                                        :ref="item.widget"
                                        :form="form"
                                        :getFormRef="getFormRef"
                                        :changeContract="changeContract"
                                        :key="item.widget"
                                        :reload="reload"
                                        :setOrderLoader="setOrderLoader"
                                        :amount="amount"
                                        :injectContractorFilter="injectContractorFilter"
                                        :edit="edit"
                                        :reloadAmount="reloadAmount"
                                        :item="item"
                                        :setOrderFormCalculated="setOrderFormCalculated"
                                        :payDatePlanRequired="payDatePlanRequired"
                                        :isOrderDrawer="isOrderDrawer"
                                        :createEmptyOrder="createEmptyOrder"/>
                                </a-form-model>
                            </div>
                            <div>
                                <div
                                    :class="[
                                        orderForm.aside.sticky && 'sticky',
                                        isMobile ? 'order_aside_mobile' : 'order_aside'
                                    ]">
                                    <div class="order_summary">
                                        <a-button
                                            v-if="orderForm.aside && orderForm.aside.calculate_button"
                                            :type="orderForm.aside.calculate_button.type"
                                            :size="orderForm.aside.calculate_button.size"
                                            class="calculate_btn"
                                            block
                                            :loading="priceLoader"
                                            @click="onSubmit(0)">
                                            {{ orderForm.aside.calculate_button.btnText || 'Рассчитать цены' }}
                                        </a-button>

                                        <div
                                            v-if="orderForm.aside.showAmount"
                                            class="summary_info">
                                            <div
                                                v-if="noDiscount"
                                                class="oth_price flex items-baseline justify-between">
                                                <span>
                                                    Сумма без скидки:
                                                </span>
                                                <div class="value">
                                                    {{ noDisAmount }} <span v-if="cartCurrency" class="ml-1">{{ cartCurrency.icon }}</span>
                                                </div>
                                            </div>
                                            <div
                                                v-if="discountSum"
                                                class="oth_price flex items-baseline justify-between">
                                                <span>
                                                    Сумма скидки:
                                                </span>
                                                <div class="value">
                                                    {{ cartDiscountSum }} <span v-if="cartCurrency" class="ml-1">{{ cartCurrency.icon }}</span>
                                                </div>
                                            </div>
                                            <div class="price flex items-baseline justify-between">
                                                <span>
                                                    Итого:
                                                </span>
                                                <div class="value">
                                                    <a-spin :spinning="cartAmountLoader">
                                                        {{ cartAmount }} <span v-if="cartCurrency" class="ml-1">{{ cartCurrency.icon }}</span>
                                                        <span v-else class="ml-1">&#8381;</span>
                                                    </a-spin>
                                                </div>
                                            </div>
                                            <div
                                                v-if="showPrepayment"
                                                class="oth_price flex items-baseline justify-between">
                                                <span>
                                                    Доступный аванс:
                                                </span>
                                                <div class="value">
                                                    {{ formatPrepayment }} <span v-if="cartCurrency" class="ml-1">{{ cartCurrency.icon }}</span>
                                                </div>
                                            </div>
                                            <div
                                                v-if="showMustpaid"
                                                class="oth_price flex items-baseline justify-between">
                                                <span>
                                                    К оплате:
                                                </span>
                                                <div class="value">
                                                    {{ formatMustpaid }} <span v-if="cartCurrency" class="ml-1">{{ cartCurrency.icon }}</span>
                                                </div>
                                            </div>
                                            <div
                                                v-if="orderForm.aside && orderForm.aside.show_nds && cartNds"
                                                class="oth_price flex items-baseline justify-between">
                                                <span>
                                                    В т.ч. НДС:
                                                </span>
                                                <div class="value">
                                                    {{ ndsSum }} <span v-if="cartCurrency" class="ml-1">{{ cartCurrency.icon }}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <a-button v-if="orderForm.calculated"
                                                  :type="orderForm.aside.orderButton.type"
                                                  :size="orderForm.aside.orderButton.size"
                                                  class="summary_btn"
                                                  block
                                                  :loading="orderLoader"
                                                  @click="onSubmit(20)">

                                            Сформировать КП

                                        </a-button>
                                        <a-button v-else
                                                  :type="orderForm.aside.orderButton.type"
                                                  :size="orderForm.aside.orderButton.size"
                                                  class="summary_btn" disabled
                                                  block
                                                  :loading="orderLoader"
                                                  @click="onSubmit()">

                                            Сформировать КП
                                        </a-button> -->
                                    </div>


                                    <!--<OrderOffer-->
                                    <!--:orderForm="orderForm"-->
                                    <!--:form="form" />-->
                                    <template v-if="orderForm && orderForm.aside && orderForm.aside.showAmountBanner && edit && showAmountBanner && Number(oldAmount) != Number(amount) && user.can_create_logistic_task">
                                        <a-alert class="mt-3" :message="`Внимание! Сумма заказа составляла ${oldAmount} ${cartCurrency.icon}. После редактирования составляет ${cartAmount} ${cartCurrency.icon}. Согласуйте с покупателем новую стоимость`" banner />
                                    </template>

                                    <div class="order-summary">
                                        <div
                                            v-if="orderForm.aside && orderForm.aside.offer && orderForm.aside.offer.show"
                                            class="offer_block mt-3">
                                            <template v-if="orderForm.aside && orderForm.aside.calculate_button">
                                                <a-button v-if="isCalculated"
                                                          :type="orderForm.aside.orderButton.type"
                                                          :size="orderForm.aside.orderButton.size"
                                                          class="summary_btn"
                                                          block
                                                          :loading="orderLoader"
                                                          @click="onSubmit(40)">
                                                    <template v-if="edit">
                                                        Сохранить
                                                    </template>
                                                    <template v-else>
                                                        {{ orderForm.aside.orderButton.btnText || 'Сформировать заказ' }}
                                                    </template>
                                                </a-button>
                                                <a-button v-else
                                                          :type="orderForm.aside.orderButton.type"
                                                          :size="orderForm.aside.orderButton.size"
                                                          class="summary_btn"
                                                          disabled
                                                          block
                                                          :loading="orderLoader"
                                                          @click="onSubmit()">
                                                    <template v-if="edit">
                                                        Сохранить
                                                    </template>
                                                    <template v-else>
                                                        {{ orderForm.aside.orderButton.btnText || 'Сформировать заказ' }}
                                                    </template>
                                                </a-button>
                                            </template>
                                            <template v-else>
                                                <a-button
                                                    :type="orderForm.aside.orderButton.type"
                                                    :size="orderForm.aside.orderButton.size"
                                                    class="summary_btn"
                                                    block
                                                    :loading="orderLoader"
                                                    @click="onSubmit(40)">
                                                    <template v-if="edit">
                                                        Сохранить
                                                    </template>
                                                    <template v-else>
                                                        {{ orderForm.aside.orderButton.btnText || 'Сформировать заказ' }}
                                                    </template>
                                                </a-button>
                                            </template>

                                            <template v-if="edit">
                                                <a-button
                                                    v-if="form.has_pay_file || alwaysShowGetPaidButton"
                                                    :loading="loadingInvoicePayment"
                                                    block
                                                    class="items-center mt-3"
                                                    @click="getInvoicePayment">
                                                    <i class="fi fi-rr-wallet mr-2"></i>
                                                    Получить счет на оплату
                                                </a-button>
                                                <a-button
                                                    v-if="alwaysShowGetPaidButton && !form.warehouse.default_warehouse"
                                                    :loading="loadingInvoice"
                                                    block
                                                    class="items-center mt-1"
                                                    @click="getInvoice">
                                                    <i class="fi fi-rr-print mr-2"></i>
                                                    Печать документов
                                                </a-button>
                                            </template>
                                        </div>
                                    </div>

                                    <!-- Блок информации о доступном остатке и лимиту по договору -->
                                    <div class="order_summary">
                                        <div
                                            v-if="showLimits"
                                            class="summary_info"
                                            style="margin-top: 10px;">
                                            <div
                                                v-if="contractLimit"
                                                class="oth_price flex items-baseline justify-between">
                                                <span>
                                                    Лимит по договору:
                                                </span>
                                                <div class="value">
                                                    {{ formatContractLimit }} <span v-if="cartCurrency" class="ml-1">{{ cartCurrency.icon }}</span>
                                                </div>
                                            </div>
                                            <div
                                                v-if="availableBalance"
                                                class="oth_price flex items-baseline justify-between">
                                                <span>
                                                    Доступный остаток:
                                                </span>
                                                <div class="value">
                                                    {{ formatAvailableBalance }} <span v-if="cartCurrency" class="ml-1">{{ cartCurrency.icon }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="order_summary">

                                        <!-- <a-button
                                            :type="orderForm.aside.orderButton.type"
                                            :size="orderForm.aside.orderButton.size"
                                            class="calculate_btn"
                                            block
                                            :loading="priceLoader"
                                            @click="onSubmit(0)">
                                            Рассчитать цены
                                        </a-button> -->

                                    </div>
                                </div>
                            </div>
                        </div>
                    </a-spin>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import {priceFormatter} from '@/utils'
import eventBus from '@/utils/eventBus.js'
import WidgetSwitch from './widgets/WidgetSwitch.vue'
//import OrderOffer from './widgets/OrderOffer.vue'
import warehouse from '../../mixins/warehouse'
let timer;
export default {
    components: {
        WidgetSwitch,
        // OrderOffer
    },
    props: {
        contractorID: {
            type: String,
            default: ''
        },
        contrsctorDeliveryPoint: {
            type: String,
            default: ''
        },
        isOrderDrawer: {
            type: Boolean,
            default: false
        },
        edit: {
            type: Boolean,
            default: false
        },
        injectOrder: {
            type: Object,
            default: () => {}
        },
        injectContractor: {
            type: Object,
            default: () => {}
        },
        closeDrawer: {
            type: Function,
            default: () => {}
        },
        showTitle: {
            type: Boolean,
            default: true
        },
        injectContractorFilter: {
            type: Object,
            default: () => {}
        },
        pageName: {
            type: String,
            default: "crm.GoodsOrderModel_list"
        }
    },
    mixins: [
        warehouse
    ],
    metaInfo() {
        return {
            title: this.pageTitle
        }
    },
    computed: {
        ...mapState({
            cartCurrency: state => state.orders.orderCurrency,
            cartList: state => state.orders.orderList,
            firstOrderLoading: state => state.orders.firstOrderLoading,
            orderEmpty: state => state.orders.orderEmpty,
            config: state => state.config.config,
            user: state => state.user.user,
            newOrders: state => state.orders.create_orders,
            orderActions: state => state.orders.orderActions
        }),
        logisticManagerOnly() {
            return this.$store.state?.user?.user?.me_logistic_manager_only
        },
        alwaysShowGetPaidButton() {
            return this.config?.order_setting?.always_show_get_paid_button || false
        },
        visible: {
            get() {
                return this.createVisible
            },
            set(val) {
                this.$store.commit('orders/SET_CREATE_VISIBLE', val)
            }
        },
        calculationButton() {
            return this.config?.order_setting?.calculationButton || true
        },
        multipleWarehousesInOrder() {
            return this.config?.order_setting?.multiple_warehouses_in_order || true
        },
        cartAmount() {
            return priceFormatter(this.amount)
        },
        noDisAmount() {
            return priceFormatter(this.noDiscount)
        },
        cartDiscountSum() {
            return priceFormatter(this.discountSum)
        },
        ndsSum() {
            return priceFormatter(this.cartNds)
        },
        formatPrepayment() {
            return priceFormatter(this.prepayment)
        },
        formatMustpaid() {
            return priceFormatter(this.mustpaid)
        },
        siteName() {
            if (this.config?.site_setting?.site_name)
                return this.config.site_setting.site_name
            else
                return 'BPMS'
        },
        pageTitle() {
            if (this.$route?.meta?.title) {
                return `${this.$route.meta.title} | ${this.siteName}`
            } else {
                return this.siteName
            }
        },
        isMobile() {
            return this.$store.state.isMobile
        },
        availableBalance () { // Вернет true если доступный остаток не равен null или undefined
            return this.availableBalanceValue !== null && this.availableBalanceValue !== undefined
        },
        contractLimit () { // Вернет true если лимит по договору не равен null или undefined
            return this.contractLimitValue !== null && this.contractLimitValue !== undefined
        },
        formatAvailableBalance() { // Добавляет разделитель разрядов
            return this.availableBalanceValue ? this.availableBalanceValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : null
        },
        formatContractLimit() { // Добавляет разделитель разрядов
            return this.contractLimitValue ? this.contractLimitValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : null
        },
        showPrepayment() { 
            return this.orderForm.aside.showPrepayment && this.prepayment
        },
        showMustpaid() {
            return this.orderForm.aside.showMustpaid && this.mustpaid
        },
        createEmptyOrder() {
            if(this.edit)
                return false
            else {
                return this.orderForm?.orderSetting?.createEmptyOrder ? true : false
            }
        },
        showOrderForm() {
            if(this.createEmptyOrder) {
                return true
            } else {
                if(this.orderForm && !this.orderEmpty) {
                    return true
                } else
                    return false
            }
        }
    },
    data() {
        return {
            form: {},
            rules: {},
            loadingInvoicePayment: false,
            loadingInvoice: false,
            reload: false,
            loading: false,
            cartAmountLoader: false,
            priceLoader: false,
            orderLoader: false,
            orderForm: null,
            currentContract: null,
            amount: '0',
            noDiscount: null,
            discountSum: null,
            cartNds: null,
            formRef: null,
            showLimits: false,
            contractLimitValue: null,
            availableBalanceValue: null,
            mustpaid: null,
            prepayment: null,
            payDatePlanRequired: false,
            oldAmount: '0',
            isCalculated: this.edit ? true : false,
            showAmountBanner: false
        }
    },
    created() {
        this.$nextTick(() => {
            this.getOrderList()
        })
    },
    methods: {
        async getInvoicePayment(){
            try {
                this.loadingInvoicePayment = true
                await this.getFiles(`crm/orders/${this.form.id}/pay_file/`)
            } catch(e) {
                console.log(e)
            } finally {
                this.loadingInvoicePayment = false
            }
        },
        async getInvoice(){
            try {
                this.loadingInvoice = true
                await this.getFiles(`crm/orders/${this.form.id}/doc_sale/`, 'Реализация товаров')
            } catch(e) {
                console.log(e)
            } finally {
                this.loadingInvoice = false
            }
        },
        async getActions() {
            try {
                await this.$store.dispatch('orders/getOrderActions', {
                    id: this.form.id
                })
            } catch(e) {
                console.log(e)
            }
        },
        async getFiles(endoint, docType='Счет'){
            if(typeof this.orderActions.blob_file === 'boolean') {
                try{
                    const { data } = await this.$http.get(endoint)
                    if(data.path) {
                        window.open(data.path, '_blank')
                    } else {
                        this.$message.warning('Нет пути к файлу')
                    }
                }
                catch(error){
                    console.log("error", error)
                    this.$message.warning(error.error_str)
                }
            } else {
                try{
                    const response = await this.$http.get(endoint, {
                        responseType: 'blob'
                    })

                    const url = window.URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `${docType} - заказ №${this.order.counter} от ${this.$moment().format('DD.MM.YYYY')}.pdf`)
                    document.body.appendChild(link)
                    link.click()
                }
                catch(error){
                    console.log("error", error)
                    this.$message.warning(error.error_str)
                }
            }
        },
        setOrderFormCalculated(val=false) {
            this.isCalculated = val
        },
        setOrderLoader(value) {
            this.orderLoader = value
            this.priceLoader = value
        },
        getShowLimits () {
            // Показать блок с лимитом по договору и доступным остатком
            if (this.contractLimitValue || this.availableBalanceValue) {
                this.showLimits = true
            }
        },
        getFormRef() {
            return this.$refs.orderForm
        },
        changeContract(val, contractList) {
            const find = contractList.find(f => f.code === val)
            if (find) {
                this.$store.commit('orders/SET_CURRENT_CONTRCAT', find.id)
            }
            this.getOrderListReload()
        },
        async getOrderListReload() {
            try {
                this.reload = true
                await this.$store.dispatch('orders/getOrderListReload')
                this.$message.info('Цена в заказе пересчитана в соответствии с выбранным соглашением')
            } catch (e) {
                console.log(e)
                this.reload = false
            } finally {
                this.reload = false
            }
        },
        async getOrderList() {
            if (this.cartList.next && !this.loading) {
                try {
                    this.loading = true
                    if(this.edit) {
                        const data = await this.$store.dispatch('orders/getOrderGoodsList', {
                            id: this.injectOrder.id
                        })
                        if (data?.results?.length) {
                            this.amount = data.amount
                            this.oldAmount = data.amount
                            await this.getWarehouseFormInfo()
                        }
                    } else {
                        const data = await this.$store.dispatch('orders/getOrderList')
                        if (data?.results?.length) {
                            this.amount = data.amount
                            await this.getWarehouseFormInfo()
                        }
                    }

                    await this.getForm()
                } catch (e) {
                    console.log(e)
                    this.loading = false
                } finally {
                    this.loading = false
                }
            }
        },
        async reloadAmount() {
            try {
                this.cartAmountLoader = true
                const { data } = await this.$http.get('/crm/shopping_cart/', {
                    params: {
                        page: 1,
                        page_size: 1
                    }
                })
                if(data) {
                    this.amount = data.amount
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.cartAmountLoader = false
            }
        },
        async getForm() {
            try {
                const {data} = await this.$http.get('/crm/orders/form_info/')
                if (data) {
                    this.orderForm = data
                    this.form = data.orderFormData
                    this.rules = data.orderFormRules

                    if(this.edit && Object.keys(this.injectOrder)?.length) {
                        const inject = this.injectOrder
                        this.form = {
                            ...this.form,
                            ...inject
                        }
                        this.getActions()
                    }
                    if(this.isOrderDrawer && this.injectContractor && Object.keys(this.injectContractor)?.length) {
                        const inject = this.injectContractor
                        this.form = {
                            ...this.form,
                            ...inject
                        }
                    }
                }
            } catch (e) {
                console.log(e)
            }
        },
        openCatalog() {
            this.$router.push({name: 'goods'})
        },
        clear() {

        },
        checkPvhValid() {
            if(this.$refs['PvhWidget']?.[0]?.$refs?.['widgetSwitch'].$refs?.['pvh_form']) {
                return this.$refs['PvhWidget'][0].$refs['widgetSwitch'].$refs['pvh_form'].partsCheck()
            } else
                return true
        },
        async submitSuccess(data) {
            const pvhForm = this.$refs['PvhWidget']?.[0]?.$refs?.['widgetSwitch'].$refs?.['pvh_form']
            if(pvhForm) {
                await pvhForm.createForm({
                    act: 'save',
                    posted: false,
                    postedStatus: true,
                    injectId: data.id
                })
            }
        },
        reloadFinalPrices() {

        },
        onSubmit(operType = 0) {
            eventBus.$emit('change_in_goods_list')
            this.$refs.orderForm.validate(async (valid, validFields) => {
                if (valid && this.checkPvhValid()) {
                    let newOrdersCount = Object.keys(this.newOrders).length
                    try {
                        this.form.oper_type = operType

                        if(operType > 0)
                            this.orderLoader = true
                        else {
                            this.showAmountBanner = false
                            this.priceLoader = true
                        }

                        let data = null

                        if(this.edit) {
                            data = await this.$store.dispatch('orders/updateOrder', this.form)
                        } else {
                            data = await this.$store.dispatch('orders/createOrder', this.form)
                        }

                        if (data) {
                            if (operType === 0) {
                                this.orderForm.calculated = data.calculated
                                this.isCalculated = data.calculated
                                this.amount = data.amount
                                this.noDiscount = data.amount_no_discount
                                this.cartNds = data.amount_nds
                                this.contractLimitValue = data.limitcontract //Лимит по договору.
                                this.availableBalanceValue = data.remcontract //Доступный остаток.
                                this.prepayment = data.prepayment //Доступный аванс
                                this.mustpaid = data.must_paid //К оплате
                                if (data.must_paid && Number(data.must_paid) >= 0) {
                                    this.form['amount_paid'] = data.must_paid
                                    // Если к оплате больше 0 делаем поле "Дата оплаты" обязательным для ввода
                                    this.payDatePlanRequired = true
                                }

                                if(data.must_paid) {
                                    this.form['must_paid'] = data.must_paid
                                }

                                this.getShowLimits() // Показать блок с лимитом по договору и доступным остатком.


                                if(data.amount_no_discount && data.amount) {
                                    this.discountSum = Number(data.amount_no_discount) - Number(data.amount)
                                }
                                // В чем отличие полей amount_paid и mustpaid приходящих с бэка?
                                // if(data.amount_paid) {
                                //     this.form.amount_paid = data.amount_paid
                                // }

                                if(this.config?.order_setting?.calculate_warehouse_tp !== 'undefined') {
                                    if(this.config.order_setting.calculate_warehouse_tp) {
                                        this.$store.commit('orders/SET_ORDER_GOOD_LIST_ONLY_RELOAD', data.tp_goods)
                                    } else {
                                        this.$store.commit('orders/SET_ORDER_GOOD_LIST_ONLY_RELOAD_NO_CALCULATE', data.tp_goods)
                                    }
                                } else {
                                    this.$store.commit('orders/SET_ORDER_GOOD_LIST_ONLY_RELOAD', data.tp_goods)
                                }
                                if(this.edit) {
                                    this.showAmountBanner = true
                                }
                            } else {
                                let action = 'create'
                                if(this.edit) {
                                    await this.submitSuccess(data)
                                    this.showAmountBanner = false
                                    this.$message.info(`Заказ ${this.form.counter} обновлен`, 5)
                                    if(newOrdersCount) {
                                        this.$message.info(`Созданы новые заказы (${newOrdersCount} шт)`, 5)
                                    }
                                    eventBus.$emit('update_order_list')
                                    this.closeDrawer()
                                    eventBus.$emit('LOGISTIC_ORDER_RELOAD')
                                    if(this.$route.query?.order) {
                                        eventBus.$emit('OPEN_ORDER_RELOAD')
                                        eventBus.$emit('update_ow_list')
                                    }
                                    action = 'update'
                                } else if(this.isOrderDrawer) {
                                    if(Array.isArray(data)) {
                                        for(let i in data) {
                                            await this.submitSuccess(data[i])
                                        }
                                    } else {
                                        await this.submitSuccess(data)
                                    }
                                    this.$message.info('Ваш заказ создан')
                                    this.showAmountBanner = false
                                    this.closeDrawer()
                                    eventBus.$emit('need_update_contractor_list')
                                    eventBus.$emit('LOGISTIC_ORDER_RELOAD')
                                    if(this.$route.query?.order) {
                                        eventBus.$emit('OPEN_ORDER_RELOAD')
                                    }

                                } else {
                                    if(Array.isArray(data)) {
                                        const redirectId = data[0].id
                                        for(let i in data) {
                                            await this.submitSuccess(data[i])
                                        }

                                        this.showAmountBanner = false
                                        eventBus.$emit('update_order_list')
                                        this.$message.info('Ваш заказ создан')

                                        if(window?.ReactNativeWebView) {
                                            window.ReactNativeWebView.postMessage(JSON.stringify({
                                                type: 'openOrders'
                                            }))
                                        } else {
                                            if(this.orderForm?.orderSetting?.redirectType) {
                                                if(this.orderForm.orderSetting.redirectType === 'orderPage')
                                                    this.$router.push({name: 'orders'})
                                                else
                                                    this.$router.push({name: 'orders', query: {order: redirectId}})
                                            } else {
                                                if(redirectId)
                                                    this.$router.push({name: 'orders', query: {order: redirectId}})
                                            }
                                        }
                                    } else {
                                        await this.submitSuccess(data)
                                        this.$message.info('Ваш заказ создан')
                                        this.showAmountBanner = false
                                        if(window?.ReactNativeWebView) {
                                            window.ReactNativeWebView.postMessage(JSON.stringify({
                                                type: 'openOrders'
                                            }))
                                        } else {
                                            if(this.orderForm?.orderSetting?.redirectType) {
                                                if(this.orderForm.orderSetting.redirectType === 'orderPage')
                                                    this.$router.push({name: 'orders'})
                                                else
                                                    this.$router.push({name: 'orders', query: {order: data.id}})
                                            } else {
                                                this.$router.push({name: 'orders', query: {order: data.id}})
                                            }
                                        }

                                        eventBus.$emit('update_order_list')
                                    }
                                }
                                eventBus.$emit(`table_row_${this.pageName}`, {
                                    action: action,
                                    row: data
                                })

                                if(window?.ReactNativeWebView) {
                                    window.ReactNativeWebView.postMessage(JSON.stringify({
                                        type: 'clearCart'
                                    }))
                                }

                            }
                        }
                    } catch (e) {
                        console.log(e)
                        this.$message.error('Ошибка оформления заказа')
                    } finally {
                        if(operType > 0)
                            this.orderLoader = false
                        else
                            this.priceLoader = false
                    }
                } else {

                    if(Object.keys(validFields)?.length) {
                        const keys = Object.keys(validFields)

                        if(keys?.[0]) {
                            const selector = document.getElementById(`field_${keys[0]}`)
                            if(selector)
                                selector.scrollIntoView({behavior: "smooth", block: "center"})
                        }
                    }

                    this.$message.warning('Заполните обязательные поля')
                    return false
                }
            })
        }
    },
    mounted() {
        eventBus.$on('contractor_is_change', () => {
            this.form['pay_type'] = null
            this.form['pay_date_plan'] = null
            this.form['amount_paid'] = 0
        })
        eventBus.$on('edit_update_price', () => {
            clearTimeout(timer)

            setTimeout(() => {
                if(this.cartList.results?.length) {
                    let am = 0
                    this.cartList.results.forEach(item => {
                        const price = item.custom_price ? parseFloat(item.custom_price) : item.price ? parseFloat(item.price) : parseFloat(item.goods.price)
                        am = am + (price * Number(item.quantity))
                    })

                    if(am) {
                        this.amount = String(am)
                    }
                } else {
                    this.amount = '0'
                }
            }, 500)
            this.setOrderFormCalculated(false)
        })
    },
    beforeDestroy() {
        this.$store.commit('orders/CLEAR_ORDER_CREATE_PAGE')
        this.$store.commit('orders/SET_IS_EMPTY_ORDER', false)
        eventBus.$off('contractor_is_change')
        eventBus.$off('edit_update_price')
    }
}
</script>

<style lang="scss" scoped>
    .radio_item {
        &:not(:last-child) {
            margin-bottom: 10px;
        }
        .form_item {
            margin-top: 10px;
        }
    }

    .create_order_page {
        .wrap_grid {
            @media(min-width: 1360px) {
                grid-template-columns: 1fr 290px;
            }
        }
        .order_aside {
            @media(min-width: 787px) {
                margin-left: auto;
                max-width: 300px;
            }
            &.sticky {
                position: sticky;
                top: 20px;
                z-index: 10;
            }
        }
        .order_summary {
            background: #eff2f5;
            border-radius: var(--borderRadius);
            .calculate_btn {
                border-radius: var(--borderRadius) var(--borderRadius) 0 0;
                font-weight: 300;
                text-transform: uppercase;
                font-size: 14px;
            }
            .summary_btn {
                border-radius: 0 0 var(--borderRadius) var(--borderRadius);
                font-weight: 300;
                text-transform: uppercase;
                font-size: 14px;
            }
            .summary_info {
                padding: 20px;
                .flex{
                    &:not(:last-child){
                        margin-bottom: 10px;
                    }
                }
            }
            .price{
                .value {
                    font-size: 20px;
                    font-weight: 600;
                }
            }
            .oth_price{
                .value {
                    font-size: 16px;
                }
            }
        }
        .order_wrap {
            max-width: 1200px;
            margin: 0 auto;
            h1 {
                font-weight: 300;
                font-size: 24px;
                margin-bottom: 30px;
            }
        }
        .order_block {
            .form_item {
                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
    }
    .create_order_mobile {
        padding: 15px;

        .order_aside_mobile {
            margin-top: 15px;
            .calculate_btn {
                border-radius: var(--borderRadius);
            }
            .summary_info {
                padding: 15px 0;
            }
        }
    }
</style>