<template>
    <div>
        <OrderShowDrawer />
        <OrderEditDrawer />
    </div>
</template>

<script>
import cart from "./store/cart/index.js"
import returnStore from "./store/return/index.js"
import OrderShowDrawer from "./components/OrdersList/OrderShowDrawer"
import OrderEditDrawer from "./components/OrdersList/OrderEditDrawer.vue"
import './assets/scss/cart.scss'
export default {
    components: { OrderShowDrawer, OrderEditDrawer },
    created() {
        if (!this.$store.hasModule("orders"))
            this.$store.registerModule("orders", cart)
        if (!this.$store.hasModule("return"))
            this.$store.registerModule("return", returnStore)
        this.getCartCount()
        this.getReturnCartCount()
    },
    methods: {
        async getCartCount() {
            try {
                await this.$store.dispatch("orders/getCartCount")
            }
            catch (e) {
                console.log(e)
            }
        },
        async getReturnCartCount() {
            try {
                await this.$store.dispatch("return/getCartCount")
            }
            catch (e) {
                console.log(e)
            }
        }
    }
}
</script>
